function useExclusionList() {
  const disallowedDisplayNames: any[] = [
    'ADP Learning',

    'AG1',

    'amkettles@aol.com',

    'amyfiorilla@gmail.com',

    'Angela L. Stueve',

    'Ann Melancon',

    'Annie Barlond-Archie',

    'Annie.Barlond-Arrchie',

    'Antione Curtis',

    'apfiorilla@optonline.net',

    'ARC',

    'Asset Management',

    'AV Room Guest',

    'Backup Admin',

    'Broad Voice',

    'Broad Voice',

    'Business',

    'Carolyn Goodbread',

    'caspough2020@gmail.com',

    'cheron99@yahoo.com',

    'Cisco LDAP',

    'Clare Dus',

    'Conference : 908-376-7090 - 5186',

    'Conference : 908-376-7091 - 4186',

    'Conference : 908-376-7092 - 3186',

    'Conference : 908-376-7093 - 2186',

    'Conference : 908-376-7094 - 1186',

    'Connection UMService',

    'ConsultantEvals',

    'Consumer Scheduling',

    'Consumer Testing',

    'cwccannon@gmail.com',

    'Dana Eng',

    'Daniela Altafini',

    'Data Management',

    'Derek Reed',

    'Discovery Center',

    'DiscoverySearchMailbox {D919BA05-46A6-415f-80AD-7E09334BB852}',

    'Discrimination Testing',

    'Eileen Irving',

    'eileenic35@aol.com',

    'Emilia Navarro',

    'Emmeline See',

    'Erica Lorusso',

    'Exchange Admin',

    'FederatedEmail.4c1f4d8b-8179-4148-93bf-00a95fa1e042',

    'FS User',

    'Ganapathykrishnan, Ragul - Contractor {PEP}',

    'gazdal.k@gmail.com',

    'Grady, Jennifer',

    'Graham, Mark',

    'Haller, Dana',

    'Hiral Patel',

    'HR',

    'HR Scans',

    'IL Food Panel Room',

    'Indigo Tidwell',

    'IT-DG',

    'Ivy Koelliker (Contractor)',

    'Ivy Koelliker',

    'Jamiee Clark',

    'Janine Anastos',

    'Jenelle Indigo',

    'Jenette Frias',

    'Jennifer Jo Wiseman',

    'Jewellen Fort',

    'Jill Misale',

    'jnawrocki704@icloud.com',

    'Joanne Rose',

    'Jobs',

    'Joey Lu',

    'john@GlobalGrange.world',

    'Josh',

    'Joyce Marchetto',

    'judlee29@hotmail.com',

    'kapkhan@yahoo.com',

    'Karen Sullivan',

    'Katie Osdoba',

    'Kerri Kroon',

    'krichline@aol.com',

    'kristinstewart13@gmail.com',

    'Laura Donovan',

    'lgtb@aol.com',

    'lindadandorph@gmail.com',

    'Linnea Scott',

    'Lissa Davis',

    'Live Stream',

    'Lynda Balog',

    'Lynn Giacobbe',

    'Lynn Gosslin',

    'maa0597@gmail.com',

    'Maria Sullivan',

    'Meet with me',

    'Melitta Towson',

    'MI Associate',

    'michelewaldron@aol.com',

    'Michigan Stats',

    'Mink, Patricia',

    'mrjohnsancho84@gmail.com',

    'MS OOO Schedule',

    'Morss, McHale {PEP}',

    'Natalie Stoer',

    'nataliestoer',

    'NC - Design Center',

    'NC - Focus Group Room',

    'NC - Observation',

    'NC - PCP Room',

    'NC Associate',

    'NC Chiproom',

    'NC Food Scheduling',

    'NC Kitchen',

    'NC Loaner',

    'New Jersey  Reception',

    'nikkibcodes',

    'NJ - Client Lounge #1',

    'NJ - Client Lounge #2',

    'NJ - Consumer Testing Room',

    'NJ - Event Room',

    'NJ - Focus Group Room #1',

    'NJ - Focus Group Room #2',

    'NJ - Food Panel Room',

    'NJ - Library',

    'NJ - Observation Room',

    'NJ - Odor Booths',

    'NJ - Prep Kitchen',

    'NJ - Projector',

    'NJ - Sink Room',

    'NJ - Staging Kitchen',

    'NJ ADP User',

    'NJ ChromeBooks',

    'NJ Data Station',

    'NJ Equipment Devices',

    'NJ Food Panel Room',

    'NJ Food Scheduling',

    'NJ Loaner',

    'NJ Shipping & Receiving',

    'North Carolina Reception',

    'North Carolina Temporary',

    'Ntiva Automate',

    'Ntiva Logic Monitor',

    'Ntiva Support',

    'On-Premises Directory Synchronization Service Account',

    'On-Premises Directory Synchronization Service Account',

    'On-Premises Directory Synchronization Service Account',

    'package_bf224e00-568a-4c5e-bad5-90841d1b1163',

    'package_c9feddb6-833d-4edd-8636-84fc63a9e89d',

    'pantherc65@gmail.com',

    'pantherschick75@gmail.com',

    'Payments',

    'perry.brown@gmail.com',

    'Personal Care Scheduling',

    'Personnel Schedule',

    'Rose, Joanne',

    'Sales Team',

    'Samuel.Remis',

    'sandi_singer@msn.com',

    'sauciea@gmail.com',

    'scan2email',

    'Scheduling',

    'Sensory Dev',

    'Sensory Spectrum',

    'Sensory Spectrum',

    'Sensory Spectrum Accreditation',

    'Sensory Spectrum',

    'Sensory Spectrum Consulting',

    'Sensory Spectrum Fax Service',

    'Sensory Spectrum Fax Service',

    'Sensory Spectrum Illinois',

    'Sensory Spectrum IT Support',

    'Sensory Spectrum New Jersey',

    'Sensory Spectrum North Carolina',

    'slcgannon@gmail.com',

    'Smith, Zachary',

    'Spectrum',

    'Spectrum Shares-NJ',

    'ss',

    'Stacy Niemann',

    'Stephanie Gieske',

    'stestuser',

    'Support Services',

    'Susan-Kellogg',

    'Susan Spectrum',

    'sweete77@hotmail.com',

    'SystemMailbox{1f05a927-668a-4c84-a46c-36faff205d28}',

    'SystemMailbox{e0dc1c29-89c3-4034-b678-e6c29d823ed9}',

    'Test User',

    'Valarie Warner',

    'Valhalla Panel Scheduling',

    'warnjh@aol.com',

    'Wendy Hoffman',

    'XLSTAT User',

    'yllkac921@gmail.com'
  ];

  return disallowedDisplayNames;
}
export default useExclusionList;
