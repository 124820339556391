import React, { useEffect, useState } from 'react';
import style from './dashboard.module.css';
import axios from 'axios';
import { urlDashboard } from '../../endpoints';
import GaugeChart from 'react-gauge-chart';
import Calendar from '../../components/Dashboard/Calendar/Calendar';
import ThreeYearMetrics from '../../components/Dashboard/ThreeYearMetrics/ThreeYearMetrics';
import BusinessClassTable from '../../components/Dashboard/BusinessClassTable/BusinessClassTable/BusinessClassTable';
import { Business } from '@mui/icons-material';
import PieChart from '../../components/Dashboard/PieChart/PieChart';

function Dashboard() {
  const [dashboard, setDashboard] = useState<any>({});
  const [billingClassTable, setBillingClassTable] = useState<any>([]);
  const [revenueVSLastMonthColor, setRevenueVSLastMonthColor] = useState(false);
  const [proposalVSLastMonthColor, setproposalVSLastMonthColor] = useState(false);

  const currentYear = new Date().getFullYear().toString();

  useEffect(() => {
    getDashboard();
    GetBusinessClassByMonth();
  }, []);

  useEffect(() => {
    if (dashboard.revenueVSLastMonth !== undefined) {
      if (dashboard.revenueVSLastMonth > 0) {
        setRevenueVSLastMonthColor(true);
      } else {
        setRevenueVSLastMonthColor(false);
      }
    }
  }, [dashboard]);

  function GetBusinessClassByMonth(selectedYear?: string) {
    if (!selectedYear) {
      selectedYear = currentYear;
    }
    axios
      .get(`${urlDashboard}/GetBusinessClassByMonth?selectedYear=${selectedYear}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` }
      })
      .then((response) => {
        //console.log(response.data);
        setBillingClassTable(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getDashboard() {
    axios
      .get(`${urlDashboard}/GetMetrics`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` }
      })
      .then((response) => {
        //console.log(response.data);
        setDashboard(response.data);
      });
  }

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  const date = new Date();
  const currentMonthName = monthNames[date.getMonth()];

  return (
    <>
      <div className={style.dashboard}>
        <div className={style.dashboard__small}>
          <h1>{currentMonthName} Invoice Amount</h1>
          <h2>
            {dashboard.totalRevenueThisMonth !== undefined
              ? dashboard.totalRevenueThisMonth.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                })
              : 'Loading...'}
          </h2>
          <p>
            Up/down{' '}
            <span style={revenueVSLastMonthColor ? { color: 'green' } : { color: 'red' }}>
              {dashboard.revenueVSLastMonth !== undefined
                ? dashboard.revenueVSLastMonth.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })
                : 'Loading...'}{' '}
            </span>
            vs last month
          </p>
        </div>
        <div className={style.dashboard__small}>
          <h1>{currentMonthName} Proposal Amount</h1>
          <h2>
            {dashboard.totalProposalThisMonth !== undefined
              ? dashboard.totalProposalThisMonth.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                })
              : 'Loading...'}{' '}
          </h2>
          <p>
            Up/down{' '}
            <span style={proposalVSLastMonthColor ? { color: 'green' } : { color: 'red' }}>
              {dashboard.proposalVSLastMonth !== undefined
                ? dashboard.proposalVSLastMonth.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })
                : 'Loading...'}{' '}
            </span>
            vs last month
          </p>
        </div>
        <div className={style.dashboard__small}>
          <h1>Project Status: Planning/Execution</h1>
          <h2>{dashboard.executionCount}</h2>
          <p>YTD</p>
        </div>
        <div className={style.dashboard__small}>
          <h1>Conversion Rate</h1>
          {/* <h1>{dashboard.conversionRate}</h1> */}
          <GaugeChart
            id='gauge-chart1'
            textColor='black'
            marginInPercent={0.025}
            percent={dashboard.conversionRate}
            colors={['#FF0000', '#FFFF00', '#00FF00']}
          />
          <p>YTD</p>
        </div>
        <div className={style.dashboard__clients}>
          <h1>
            Top Clients 2025<br></br>
            <span style={{ fontSize: '.8rem' }}> (Proposals Accepted/Completed)</span>
          </h1>
          <div>
            {dashboard.topClientListProposalsAccepted &&
              dashboard.topClientListProposalsAccepted.map((client: any, index: any) => (
                <p key={index}>
                  {index + 1}. {client.clientName}{' '}
                  {client.totalAmount.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0
                  })}
                </p>
              ))}
          </div>
        </div>
        <div className={style.dashboard__pieChart}>
          <h1>
            Proposal Amount by Business Class <span style={{ fontSize: '.8rem' }}>(in USD)</span>
          </h1>
          <PieChart data={dashboard.pieChartData} />
        </div>
        <div className={style.dashboard__clients}>
          <h1>
            Top Clients 2025<br></br>
            <span style={{ fontSize: '.8rem' }}> (Proposals Written)</span>
          </h1>
          <div>
            {dashboard.topClientListProposalsWritten &&
              dashboard.topClientListProposalsWritten.map((client: any, index: any) => (
                <p key={index}>
                  {index + 1}. {client.clientName}{' '}
                  {client.totalAmount.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0
                  })}
                </p>
              ))}
          </div>
        </div>
        <div className={style.dashboard__smallTable}>
          {dashboard.threeYearMetrics !== undefined ? (
            <ThreeYearMetrics dashboard={dashboard} />
          ) : (
            'Loading...'
          )}
        </div>
        <div className={style.dashboard__calendar}>
          <h1>
            Proposal Amount by Day <span style={{ fontSize: '.8rem' }}>(in USD)</span>
          </h1>
          {/* <div style={{ height: '900px', width: '900px' }}> */}

          {dashboard.proposalCalendar !== undefined ? (
            <Calendar data={dashboard.proposalCalendar} />
          ) : (
            'Loading...'
          )}
          {/* </div> */}
        </div>
        <div className={style.dashboard__table}>
          <BusinessClassTable
            dashboard={dashboard}
            billingClassTable={billingClassTable}
            GetBusinessClassByMonth={GetBusinessClassByMonth}
          />
        </div>
      </div>
    </>
  );
}

export default Dashboard;
