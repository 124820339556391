import React, { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';

export function useAuthenticateDatabase() {

  function checkTokenDatabase(): boolean {
    let token = sessionStorage.getItem('accessToken');

    if (token === null || token === undefined) {
      console.log('Token null or undefined')
      return false;
    } else {
    let decodeToken: { groups: string[] } = jwtDecode(token as string);
      if (decodeToken.groups.includes('428c0a9e-7c28-450d-9183-363effa5ad6b')) {
        console.log('Access Denied');
        return true;
      } else {
        return false;
      }
  }
  }

  return checkTokenDatabase;
}

export default useAuthenticateDatabase;
