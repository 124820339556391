import React from 'react';
import style from './threeYearMetrics.module.css';

function ThreeYearMetrics(props: any) {
  if (!props.dashboard || !props.dashboard.threeYearMetrics) {
    return null;
  }

  // Sort the metrics by year
  const sortedMetrics = [...props.dashboard.threeYearMetrics].sort((a, b) => a.year - b.year);

  return (
    <div>
      <table className={style.table}>
        <thead>
          <tr className={style.headingBoarder}>
            <th>Status</th>
            {sortedMetrics.map((metric: any, index: number) => (
              <th key={index}>{metric.year}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr className={style.row}>
            <td>Not Accepted</td>
            {sortedMetrics.map((metric: any, index: number) => (
              <td key={index}>{metric.cancelledCount}</td>
            ))}
          </tr>
          <tr className={style.row}>
            <td>Proposal</td>
            {sortedMetrics.map((metric: any, index: number) => (
              <td key={index}>{metric.proposalCount}</td>
            ))}
          </tr>
          <tr className={style.row}>
            <td>Planning Execution</td>
            {sortedMetrics.map((metric: any, index: number) => (
              <td key={index}>{metric.executionCount}</td>
            ))}
          </tr>
          <tr className={style.row}>
            <td>Completed</td>
            {sortedMetrics.map((metric: any, index: number) => (
              <td key={index}>{metric.completedCount}</td>
            ))}
          </tr>
          <tr className={style.row}>
            <td className={style.total}>Total</td>
            {sortedMetrics.map((metric: any, index: number) => (
              <td key={index} className={style.total}>
                {metric.completedCount +
                  metric.proposalCount +
                  metric.executionCount +
                  metric.cancelledCount}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default ThreeYearMetrics;
