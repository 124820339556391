import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import './project.css';
import Pagination from '../../../components/utils/Pagination/Pagination';
import axios, { AxiosResponse } from 'axios';
import { useMsal } from '@azure/msal-react';
import { urlContacts, urlProjects, urlClients } from '../../../endpoints';
import { projectDTO } from '../../../DTOs/projects.model';
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Collapse from 'react-bootstrap/Collapse';
import Table from 'react-bootstrap/Table';
import DialogModel from '../DialogModel/dialogmodel';
import CommentModel from '../CommentModel/CommentModel';
import SampleModel from '../SampleModel/sampleModel';
import { loginRequest } from '../../../auth/authConfig';
import { textAlign } from '@mui/system';
import { Button } from 'react-bootstrap';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { PublicClientApplication } from '@azure/msal-browser';
import { accessTokenRequest } from '../../../auth/authConfig';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import PrimaryButton from '../../../components/utils/PrimaryButton/PrimaryButton';
import ConfirmModal from '../../Contact/ConfirmModal/ConfirmModal';
import { Filter } from 'heroicons-react';
import ReportModal from '../ReportModal/ReportModal';
import { send } from 'process';
import { useGetClients, useClientList } from '../../../context/ClientContext';
import { useUserList, useGetUserList } from '../../../context/UserContext';
import Spinner from 'react-bootstrap/Spinner';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { set } from 'react-hook-form';
import useExclusionList from '../../../hooks/useExclusionList';
import TableNumberSelect from '../../../components/common/TableNumberSelect/TableNumberSelect';

export default function Home(props: any) {
  const { name, userEmail } = props;

  let PageSize = 5;
  const [apiData, setApiData] = useState(null);
  const { instance, accounts, inProgress } = useMsal();
  const [projects, setProjects] = useState<any[]>([]);
  const [render, setRender] = useState(0);
  const [totalAmountOfPages, setTotalAmountOfPages] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [SortDialogopen, setSortDialogopen] = useState(false);
  const [ActiveSortBtnValue1, SetSortBtnValue1] = useState(false);
  const [ActiveSortBtnValue2, SetSortBtnValue2] = useState(false);
  const [ActiveSortBtnValue3, SetSortBtnValue3] = useState(false);
  const [ActiveSortBtnValue4, SetSortBtnValue4] = useState(false);
  const [ActiveSortBtnValue5, SetSortBtnValue5] = useState(false);
  const [ActiveSortBtnValue6, SetSortBtnValue6] = useState(false);
  const [ActiveSortBtnValue7, SetSortBtnValue7] = useState(false);
  const [ActiveSortBtnValue8, SetSortBtnValue8] = useState(false);

  const [FilterArray, SetFilterArray] = useState<{ key: string; value: string }[]>([]);
  const [overallCategory, SetOverallCategory] = useState('');
  // const [requiresTravel, SetRequiresTravel] = useState('');
  const [clientFilter, SetClientFilter] = useState('');
  const [projectName, SetProjectName] = useState('');
  const [proposalDate, SetProposalDate] = useState('');
  const [projectLead, SetProjectLead] = useState('');
  const [proposalAmount, SetProposalAmount] = useState('');
  const [projectStatus, SetProjectStatus] = useState('');
  const [projectTableStatus, SetProjectTableStatus] = useState('');
  const [operator, SetOperator] = useState('OR');

  const [sortWith, SetSortWith] = useState('');
  const [sortBy, SetSortBy] = useState('dec');
  const [search, setSearch] = useState('');
  const [searchPayload, setSearchPayload] = useState('');
  const [modalShow, setModalShow] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState('Add New Project');

  const [commentmodalShow, setCommentmodalShow] = React.useState(false);
  const [sampleModalShow, setSampleModalShow] = React.useState(false);

  const [contactList, setContactList] = useState<any[]>([]);
  //const [clientList, setClientList] = useState<any[]>([]);
  const [clientId, setClientId] = useState<any>(undefined);
  // const [employeeData, setEmployeeData] = useState<any[]>([]);
  const [commentModalShow, setCommentModalShow] = React.useState(false);

  const [clear, SetClear] = useState(true);
  const [checked, setChecked] = useState(true);
  const [projectId, setProjectId] = useState<any>(undefined);
  const [defaultEmail, setDefaultEmail] = useState('jnelson@sensoryspectrum.onmicrosoft.com');
  const [sendEmailState, setSendEmailState] = useState(false);
  const [reportModalShow, setReportModalShow] = useState(false);
  const [rowValue, setRowValue] = useState<any>(undefined);
  const [sampleEmail, setSampleEmail] = useState<any>(false);
  const [toastShow, setToastShow] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastHeaderMessage, setToastHeaderMessage] = useState('');
  const [toastType, setToastType] = useState('');
  const [sampleModel, setSampleModel] = useState({
    nameOrCode: '',
    quantity: 0,
    unitSize: '',
    sampleId: 0,
    returnDate: '',
    infoNotes: '',
    productStatus: '',
    clientId: 0,
    dateTime: '',
    enteredByOrOtherInfo: `${props.name}`,
    projectId: 0
  });

  let classNameRef = useRef('');
  let valueRef = useRef('');
  let count = useRef(0);

  const buttonText = 'New Project';

  const getClients: () => Promise<void> = useGetClients();
  const clientList = useClientList();
  const employeeData = useUserList();
  const getUserList: () => Promise<void> = useGetUserList();
  const disallowed: any = useExclusionList();

  const filteredEmployeeData = employeeData.filter(
    (value) => !disallowed.includes(value.displayName)
  );

  function clearProjectId() {
    setProjectId(undefined);
  }

  function HandleRecordsPerPage(value: number) {
    setRecordsPerPage(value);
  }

  function handleSampleModel(fromChild: any) {
    const x = { ...fromChild };
    x.projectId = projectId;
    x.clientId = clientId;
    x.enteredByOrOtherInfo = name;
    x.SampleId = fromChild.sampleId;
    x.nameOrCode = fromChild.nameOrCode;
    x.quantity = fromChild.quantity;
    x.unitSize = fromChild.unitSize;
    x.returnDate = fromChild.returnDate;
    x.infoNotes = fromChild.infoNotes;
    x.productStatus = fromChild.productStatus;
    x.dateTime = fromChild.dateTime;
    setSampleModel(x);
    setSampleEmail(true);
  }

  function handleSetReportModalShow() {
    if (reportModalShow === false) {
      setReportModalShow(true);
    } else {
      setReportModalShow(false);
    }
  }

  function handleToast(response: any) {
    switch (response) {
      case 'Success':
        setToastHeaderMessage('Saved!');
        setToastMessage('Project Saved Successfully');
        setToastType('success');
        setToastShow(true);
        break;
      case 'Note Saved':
        setToastHeaderMessage('Saved!');
        setToastMessage('Note Saved Successfully');
        setToastType('success');
        setToastShow(true);
        break;
      case 'Note Updated':
        setToastHeaderMessage('Saved!');
        setToastMessage('Note Updated Successfully');
        setToastType('success');
        setToastShow(true);
        break;
      case 'Note Deleted':
        setToastHeaderMessage('Deleted!');
        setToastMessage('Note Deleted Successfully');
        setToastType('secondary');
        setToastShow(true);
        break;
      case 'Team Member Removed':
        setToastHeaderMessage('Removed!');
        setToastMessage('Team Member will no longer receive emails');
        setToastType('secondary');
        setToastShow(true);
        break;
      case 'Sample Saved':
        setToastHeaderMessage('Saved!');
        setToastMessage('Sample has been saved');
        setToastType('success');
        setToastShow(true);
        break;
      case 'Accepted':
        setToastHeaderMessage('Report Sent');
        setToastMessage('Report has been sent to your emil.');
        setToastType('success');
        setToastShow(true);
        break;
      default:
        setToastHeaderMessage('Failed!');
        setToastMessage('An Error Occured.');
        setToastType('danger');
        setToastShow(true);
    }
  }

  useLayoutEffect(() => {
    if (!apiData && inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent({
          account: accounts[0],
          scopes: [`${process.env.REACT_APP_API_PROTECTED_RESOURCE_API_SCOPES}`]
        })
        .then((accessTokenResponse) => {
          let accessToken = accessTokenResponse.accessToken;
          sessionStorage.setItem('accessToken', accessToken);
          RequestAccessToken();
          getProjects(accessToken);
          // getContacts(accessToken);
          getClients();
          // getClients(accessToken).then((response: any) => {
          //   setClientList(response);
          // });
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              account: accounts[0],
              scopes: [`${process.env.REACT_APP_API_PROTECTED_RESOURCE_API_SCOPES}`]
            });
          }
          console.log(error);
        });
    }
  }, [
    instance,
    accounts,
    inProgress,
    apiData,
    page,
    recordsPerPage,
    totalAmountOfPages,
    sortWith,
    sortBy,
    //search
    searchPayload
  ]);

  function RequestAccessToken() {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    return instance
      .acquireTokenSilent(request)
      .then((response: any) => {
        sessionStorage.setItem('emailToken', response.accessToken);
        // getMSGraphUserList(response.accessToken);
        getUserList();
      })
      .catch((e) => {
        instance.acquireTokenPopup(request).then((response: any) => {
          // getMSGraphUserList(response.accessToken);
          getUserList();
        });
      });
  }

  function changeStatus(projectId: any, status: any) {
    if (sendEmailState) {
      setSendEmailState(false);
    }
    axios
      .put(
        `${urlProjects}/ChangeStatus?projectId=${projectId}&status=${status}`,
        {},
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` }
        }
      )
      .then((response) => {
        if (response.status === 204) {
          // getSales();
          //window.location.reload();
          getProjects(sessionStorage.getItem('accessToken'));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (sendEmailState) {
      sendEmail(sessionStorage.getItem('emailToken'));
    }
    if (sampleEmail) {
      setSampleEmail(false);
    }
  }, [sendEmailState]);

  function sendEmail(token: any) {
    // RequestAccessToken();
    var matchingProject = projects.find(function (project) {
      return project.projectId === projectId;
    });
    let matchingEmployeeIds: any[] = [];
    for (let i = 0; i < matchingProject.projectEmployees.length; i++) {
      let p = matchingProject.projectEmployees[i];
      matchingEmployeeIds.push(p);
    }
    var employeeEmailsBody = [];
    for (let i = 0; i < matchingEmployeeIds.length; i++) {
      employeeEmailsBody.push({
        emailAddress: {
          address:
            employeeData?.find((x: any) => x.id === matchingEmployeeIds[i].employeeId) === undefined
              ? null
              : employeeData?.find((x: any) => x.id === matchingEmployeeIds[i].employeeId)
                  .userPrincipalName
        }
      });
    }
    if (!sampleEmail) {
      var body = {
        message: {
          subject: `${matchingProject.projectId} Project Status Changed`,
          body: {
            contentType: 'Text',
            content: `Project: ${matchingProject.projectId} ${matchingProject.projectName} status has been changed to ${valueRef.current}`
          },
          toRecipients: employeeEmailsBody
        },
        saveToSentItems: 'true'
      };
      var payload = JSON.stringify(body);
      axios
        // .post(`https://graph.microsoft.com/v1.0/users/${defaultEmail}/sendMail`, payload, {
        .post(`https://graph.microsoft.com/v1.0/me/sendMail`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        })
        .then(() => {
          // setProjectId(undefined);
          setSendEmailState(false);
        })
        .catch((error: any) => {
          console.error(error);
        });
    }
    if (sampleEmail) {
      // var productReceivedBody = {
      //   message: {
      //     subject: `${matchingProject.projectId} Product Received`,
      //     body: {
      //       contentType: 'Text',
      //       content: `Project: ${matchingProject.projectId} ${
      //         matchingProject.projectName
      //       } has received Samples.
      //       \n-New Sample-\nSample Name: ${sampleModel.nameOrCode} \nDate: ${
      //         sampleModel.returnDate
      //       }\nQuantity: ${sampleModel.quantity} \nUnit Size: ${
      //         sampleModel.unitSize
      //       }  \n${matchingProject.samples
      //         .map((x: any) => {
      //           return `
      //           \nSample Id: ${x.sampleId} \nSample Name: ${x.nameOrCode} \nDate: ${x.returnDate} \nQuantity: ${x.quantity} \nUnit Size: ${x.unitSize}`;
      //         })
      //         .join('\n')}
      //       `
      //       // content: `Project: ${matchingProject.projectName} has received the following product ${valueRef.current}`
      //     },
      //     toRecipients: employeeEmailsBody
      //   },
      //   saveToSentItems: 'true'
      // };s

      const sampleTable = `

      New Sample
       <br><br/>
      <ul style="margin-top: 0px; padding-top: 0px">
      <li>Sample Name: ${sampleModel.nameOrCode}</li>
      <li>Date: ${sampleModel.returnDate}</li>
      <li>Quantity: ${sampleModel.quantity}</li>
      <li>Unit Size: ${sampleModel.unitSize}</li>
      </ul>
      <br><br/>
      Additional Samples:
  <div style="margin-top: 5px ">
    <table style="border-collapse: collapse; width: 100%;">
      <thead>
        <tr>
          <th style="white-space: nowrap; border: 1px solid black;">Sample Id</th>
          <th style="border: 1px solid black;">Sample Name</th>
          <th style="border: 1px solid black;">Date</th>
          <th style="border: 1px solid black;">Quantity</th>
        </tr>
      </thead>
      <tbody>
        ${
          matchingProject.samples
            ? matchingProject.samples
                .map(
                  (x: any) => `
          <tr>
            <td style="border: 1px solid black;">${x.sampleId}</td>
            <td style="border: 1px solid black;">${x.nameOrCode}</td>
            <td style="border: 1px solid black;">${x.returnDate}</td>
            <td style="border: 1px solid black;">${x.quantity}</td>
          </tr>
          
        `
                )
                .join('')
            : ''
        }
      </tbody>
    </table>
  </div>
`;

      var productReceivedBody = {
        message: {
          subject: `${matchingProject.projectId} Product Received`,
          body: {
            contentType: 'HTML',
            content: `${sampleTable}`

            // content: `Project: ${matchingProject.projectName} has received the following product ${valueRef.current}`
          },
          toRecipients: employeeEmailsBody
        },
        saveToSentItems: 'true'
      };
      var payload = JSON.stringify(productReceivedBody);
      axios
        //.post(`https://graph.microsoft.com/v1.0/users/${defaultEmail}/sendMail`, payload, {
        .post(`https://graph.microsoft.com/v1.0/me/sendMail`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        })
        .then(() => {
          // setProjectId(undefined);
          setSendEmailState(false);
          ClearSampleModel();
        })
        .catch((error: any) => {
          console.error(error);
        });
    }
  }

  function ClearSampleModel() {
    const x = { ...sampleModel };
    x.nameOrCode = '';
    x.clientId = 0;
    x.quantity = 0;
    x.unitSize = '';
    x.returnDate = '';
    x.infoNotes = '';
    x.productStatus = '';
    x.dateTime = '';
    setSampleModel(x);
  }

  function getColorClass(option: any) {
    switch (option) {
      case 'Planning/Execution':
        return 'bg-color-green form-select color-white';
      case 'Proposal':
        return 'bg-color-pending form-select color-white';
      case 'Completed':
        return 'bg-color-white form-select';
      case 'Not Accepted/Cancelled':
        return 'bg-color-white form-select color-danger';
      default:
        return 'form-select';
    }
  }

  useEffect(() => {
    classNameRef.current = getColorClass(valueRef.current);
  }, []);

  useEffect(() => {
    getProjects(sessionStorage.getItem('accessToken'));
    SetFilterArray([]);
  }, [checked]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSearchPayload(search);
    }, 500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [search]);

  function getProjects(accessToken: any) {
    var params = {
      page,
      recordsPerPage,
      filters: '',
      sortBy: 'asc',
      sortWith: '',
      search: ''
    };
    if (checked === false) {
      params.filters = JSON.stringify([{ key: 'project lead', value: `${name}` }]);
    }
    if (FilterArray.length > 0) {
      params.filters = JSON.stringify(FilterArray);
    }
    if (sortWith !== '') {
      params.sortWith = sortWith;
    }
    if (searchPayload !== '') {
      params.search = search;
    }
    params.sortBy = sortBy;

    axios
      .get(urlProjects, {
        params: params,
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` }
      })
      .then((response: any) => {
        const totalAmountOfRecords = response.data.data.totalRecords;

        setTotalAmountOfPages(Math.ceil(totalAmountOfRecords / recordsPerPage));
        setProjects(response.data.data.data);
        setRender((count.current = count.current + 1));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getContacts(accessToken: any) {
    axios
      .get(`${urlContacts}`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      })
      .then((response: any) => {
        setContactList(response.data.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    document.querySelectorAll('.status').forEach((element) => {
      if (element.innerHTML === 'Active') {
        element.setAttribute('class', 'status-paid');
      } else if (element.innerHTML === 'Pending') {
        element.setAttribute('class', 'status-pending');
      } /*else if (element.innerHTML === 'Unpaid'){} */
    });
  }, [render]);

  function deleteItem(id: any) {
    axios
      .delete(`${urlProjects}/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
        }
      })
      .then(() => {
        getProjects(sessionStorage.getItem('accessToken'));
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function handleComment(id: any, fromChild: boolean) {
    setProjectId(id);
    setCommentModalShow(fromChild);
  }

  function handleSample(id: any, clientId: any) {
    setProjectId(id);
    setClientId(clientId);
    setSampleModalShow(true);
  }

  function handleSampleModalShow(fromChild: boolean) {
    setSampleModalShow(fromChild);
  }

  function handleCommentModalShow(fromChild: boolean) {
    setCommentModalShow(fromChild);
  }

  function editItem(id: any) {
    setProjectId(id);
    setModalTitle('Edit Project');
    setModalShow(true);
  }

  async function handleEditModalShow(fromChild: boolean) {
    if (sampleModalShow === false) {
      await setModalShow(fromChild);
    }
  }

  function GetFilterArray(key: string, value: string) {
    if (value !== '') {
      var isexist = FilterArray.find((x: any) => x.key === key);
      if (isexist !== undefined) {
        var fa: any = FilterArray.map((x: any) => {
          if (x.key === key) {
            x.value = value;
          }
          return x;
        });
        SetFilterArray(fa);
      } else {
        var obj = {
          key: key,
          value: value
        };
        SetFilterArray([...FilterArray, obj] as any);
      }
    } else {
      SetFilterArray(FilterArray.filter((x: any) => x.key !== key));
    }
  }

  // function GetFilterArray(key: string, value: string) {
  //   const operatorIndex = FilterArray.findIndex((x: any) => x.key === 'operator');
  //   if (operatorIndex > -1) {
  //     FilterArray[operatorIndex].value = 'AND';
  //   } else {
  //     FilterArray.push({ key: 'operator', value: 'AND' });
  //   }

  //   if (value !== '') {
  //     var isexist = FilterArray.find((x: any) => x.key === key);
  //     if (isexist !== undefined) {
  //       var fa: any = FilterArray.map((x: any) => {
  //         if (x.key === key) {
  //           x.value = value;
  //         }
  //         return x;
  //       });
  //       SetFilterArray(fa);
  //     } else {
  //       var obj = {
  //         key: key,
  //         value: value
  //       };
  //       SetFilterArray([...FilterArray, obj] as any);
  //     }
  //   } else {
  //     SetFilterArray(FilterArray.filter((x: any) => x.key !== key));
  //   }
  // }

  useEffect(() => {
    if (
      FilterArray.length > 0 ||
      clientFilter !== '' ||
      projectName !== '' ||
      proposalDate !== '' ||
      projectLead !== '' ||
      proposalAmount !== '' ||
      projectStatus !== '' ||
      projectTableStatus !== ''
    ) {
      SetClear(false);
    } else {
      SetClear(true);
    }
  }, [FilterArray.length, open]);

  function ClearFilter() {
    SetFilterArray([]);
    SetOverallCategory('');
    SetClientFilter('');
    SetProjectName('');
    SetProposalDate('');
    SetProjectLead('');
    SetProposalAmount('');
    SetProjectStatus('');
    SetOperator('OR');
    // setOpen(() => !open);
    axios
      .get(urlProjects, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` }
      })
      .then((response: any) => {
        const totalAmountOfRecords = response.data.data.totalRecords;

        setTotalAmountOfPages(Math.ceil(totalAmountOfRecords / recordsPerPage));
        setProjects(response.data.data.data);
        setRender((count.current = count.current + 1));
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function ApplyFilter() {
    getProjects(sessionStorage.getItem('accessToken'));
    setOpen(() => !open);
    // SetOverallCategory('');
    // SetClientFilter('');
    // SetProjectName('');
    // SetProposalDate('');
    // SetProjectLead('');
    // SetProposalAmount('');
    // SetProjectStatus('');
    // SetOperator('OR');
  }

  const [value, setValue] = useState({
    status: 'Pending',
    projectId: 1
  });

  return (
    <>
      <div className='col-12'>
        <div className='row m-0'>
          <div>
            <div className='container-fluid'>
              <div className='py-4 heading'>Projects </div>
              <div className='col-12'>
                <div className='row'>
                  <div className='col-8'>
                    <div className='row'>
                      <div className='col-4 d-flex align-items-center'>
                        <div className='position-relative'>
                          <input
                            type='text'
                            className='form-control paddingLeft border-0'
                            placeholder='Search'
                            required
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                              if (e.target.value === '') {
                                setSearchPayload('');
                              }
                            }}
                            // onChange={(e) => {
                            // setTimeout(() => setSearch(e.target.value), 1000);
                            // }}
                          />
                          <svg
                            className='icon-input'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 512 512'
                          >
                            <path d='M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z' />
                          </svg>
                        </div>
                      </div>

                      <div
                        className='col-3 d-flex align-items-center justify-content-center'
                        style={{ position: 'relative' }}
                      >
                        <>
                          <button
                            onClick={() => {
                              // SetFilterArray([]);
                              setSearch('');
                              setChecked(true);
                              setOpen(() => !open);
                              setSortDialogopen(() => false);
                            }}
                            aria-expanded={open}
                            style={{
                              backgroundColor: 'white',
                              padding: '2%',
                              color: 'black',
                              borderColor: 'white',
                              border: '0px'
                            }}
                          >
                            <div className='d-flex align-item-center'>
                              <img src='/assets/img/filter.svg' alt='Filter' />

                              <div>
                                <p className='px-2 mb-0'>Filters</p>
                              </div>
                              <div
                                style={{ marginLeft: '5px', marginTop: '3%' }}
                                className={open === true ? 'fa fa-caret-up' : 'fa fa-caret-down'}
                              ></div>
                            </div>
                          </button>
                          <div style={{ minHeight: '80px' }}>
                            <Collapse in={open} dimension='width'>
                              <div id='example-collapse-text'>
                                <Card
                                  body
                                  style={{
                                    width: '900px',
                                    borderRadius: '24px',
                                    position: 'absolute',
                                    boxShadow: '0px 0px 83px -48px black',
                                    top: '40%',
                                    left: '0%',
                                    zIndex: '1'
                                  }}
                                >
                                  <>
                                    <div className='container modal_form'>
                                      <div className='row' style={{ width: '100%' }}>
                                        <br />
                                        {/*---------------------------------- try new dropdown of filter ---------------------------------- */}

                                        <div className='col-4'>
                                          <br />
                                          <div className='position-relative'>
                                            <p
                                              style={{
                                                fontWeight: '500',
                                                textAlign: 'center',
                                                marginLeft: '-30%'
                                              }}
                                            >
                                              Project Lead
                                            </p>
                                            <select
                                              onChange={(e: any) => {
                                                SetProjectLead(e.target.value);
                                                GetFilterArray('project lead', e.target.value);
                                              }}
                                              className='form-select'
                                              aria-label='Default select example'
                                              value={projectLead}
                                            >
                                              <option
                                                value=''
                                                selected
                                                style={{
                                                  color: 'red'
                                                }}
                                              >
                                                None
                                              </option>
                                              {filteredEmployeeData.map
                                                ? filteredEmployeeData.map((value, index) => (
                                                    <option key={index}>
                                                      <div>{value.displayName}</div>
                                                    </option>
                                                  ))
                                                : null}
                                            </select>
                                          </div>
                                        </div>
                                        <div className='col-4'>
                                          <br />
                                          <div className='position-relative'>
                                            <p
                                              style={{
                                                fontWeight: '500',
                                                textAlign: 'center',
                                                marginLeft: '-30%'
                                              }}
                                            >
                                              Status
                                            </p>
                                            <select
                                              onChange={(e: any) => {
                                                SetProjectStatus(e.target.value);
                                                GetFilterArray('status', e.target.value);
                                              }}
                                              className='form-select'
                                              aria-label='Default select example'
                                              value={projectStatus}
                                            >
                                              <option
                                                value=''
                                                selected
                                                style={{
                                                  color: 'red'
                                                }}
                                              >
                                                None
                                              </option>

                                              <option value='Proposal'>Proposal</option>
                                              <option value='Planning/Execution'>
                                                Planning/Execution
                                              </option>
                                              <option value='Not Accepted/Cancelled'>
                                                Not Accepted/Cancelled
                                              </option>
                                              <option value='Completed'>Completed</option>
                                              {/* <option value='Test Complete'>Test Complete</option>
                                                      <option value='Cancelled'>Cancelled</option> */}
                                            </select>
                                          </div>
                                        </div>
                                        <div className='col-4'>
                                          <br />
                                          <div className='position-relative'>
                                            <p
                                              style={{
                                                fontWeight: '500',
                                                textAlign: 'center',
                                                marginLeft: '-30%'
                                              }}
                                            >
                                              Overall Category{' '}
                                            </p>
                                            <select
                                              onChange={(e: any) => {
                                                SetOverallCategory(e.target.value);
                                                GetFilterArray('overall category', e.target.value);
                                              }}
                                              className='form-select'
                                              aria-label='Default select example'
                                              value={overallCategory}
                                            >
                                              <option
                                                value=''
                                                selected
                                                style={{
                                                  color: 'red'
                                                }}
                                              >
                                                None
                                              </option>
                                              {/* <option selected>Select...</option> */}
                                              <option value='Consulting'>Consulting</option>
                                              <option value='Consumer Product'>
                                                Consumer + Product
                                              </option>
                                              <option value='Consumer Understanding'>
                                                Consumer Understanding
                                              </option>
                                              <option value='Discrimination'>Discrimination</option>
                                              <option value='NC Consulting'>NC Consulting</option>
                                              <option value='Personal Care'>Personal Care</option>
                                              <option value='Product Understanding'>
                                                Product Understanding
                                              </option>
                                              <option value='Training and Education'>
                                                Training and Education
                                              </option>
                                              <option value='Other'>Other</option>
                                            </select>
                                          </div>
                                        </div>
                                        {/* <div className='col-4'>
                                          <br />
                                          <div className='position-relative'>
                                          <p
                                          style={{
                                            fontWeight: '500',
                                            textAlign: 'center',
                                                marginLeft: '-30%'
                                              }}
                                            >
                                              Project Name
                                              </p>
                                              <select
                                              onChange={(e: any) => {
                                                SetProjectName(e.target.value);
                                                GetFilterArray('project name', e.target.value);
                                              }}
                                              className='form-select'
                                              aria-label='Default select example'
                                              value={projectName}
                                            >
                                              <option
                                                value=''
                                                selected
                                                style={{
                                                  color: 'red'
                                                }}
                                                >
                                                None
                                              </option>
                                              {projects?.map((value, index) => (
                                                <option key={index}>
                                                <div>{value.projectName}</div>
                                                </option>
                                                ))}
                                                </select>
                                                </div>
                                        </div> */}
                                      </div>
                                      <div
                                        className='row'
                                        style={{
                                          width: '100%',
                                          marginTop: '1.5%'
                                        }}
                                      >
                                        <br />

                                        {/*---------------------------------- 2nd row dropdown of filter ---------------------------------- */}
                                        <div className='col-4'>
                                          <br />
                                          <div className='position-relative'>
                                            <p
                                              style={{
                                                fontWeight: '500',
                                                textAlign: 'center',
                                                marginLeft: '-30%'
                                              }}
                                            >
                                              Client{' '}
                                            </p>
                                            <select
                                              onChange={(e: any) => {
                                                SetClientFilter(e.target.value);
                                                GetFilterArray('client', e.target.value);
                                              }}
                                              className='form-select'
                                              aria-label='Default select example'
                                              value={clientFilter}
                                            >
                                              <option
                                                value=''
                                                selected
                                                style={{
                                                  color: 'red'
                                                }}
                                              >
                                                None
                                              </option>
                                              {clientList?.map((value: any, index: any) => (
                                                <option key={index}>
                                                  <div>{value.clientName}</div>
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                        </div>
                                        <div className='col-4'>
                                          <br />
                                          <div className='position-relative'>
                                            <p
                                              style={{
                                                fontWeight: '500',
                                                textAlign: 'center',
                                                marginLeft: '-30%'
                                              }}
                                            >
                                              Proposal Date
                                            </p>
                                            <input
                                              onChange={(e: any) => {
                                                SetProposalDate(e.target.value);
                                                GetFilterArray('proposal date', e.target.value);
                                              }}
                                              style={{
                                                width: '100%'
                                              }}
                                              type='date'
                                              value={proposalDate}
                                              className='form-control'
                                              placeholder='Spec-1'
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className='row'
                                        style={{
                                          width: '100%',
                                          marginTop: '1.5%'
                                        }}
                                      >
                                        <br />

                                        {/*---------------------------------- 3rd row dropdown of filter ---------------------------------- */}

                                        <div className='col-4'>
                                          <br />
                                          <div className='position-relative'>
                                            <p
                                              style={{
                                                fontWeight: '500',
                                                textAlign: 'center',
                                                marginLeft: '-30%'
                                              }}
                                            >
                                              Filter Type
                                            </p>
                                            <select
                                              onChange={(e: any) => {
                                                SetOperator(e.target.value);
                                                GetFilterArray('operator', e.target.value);
                                              }}
                                              className='form-select'
                                              aria-label='Default select example'
                                              value={operator}
                                            >
                                              {/* <option
                                                selected
                                                style={{
                                                  color: 'red'
                                                }}
                                                value=''
                                              >
                                                None
                                              </option> */}
                                              <option value='And'>AND</option>
                                              <option value='OR'>OR</option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className='row justify-content-end'
                                      style={{ margin: '2% 1% 0 0' }}
                                    >
                                      <div className=' d-flex justify-content-end'>
                                        <ToggleButton
                                          id='toggle-clear'
                                          type='checkbox'
                                          variant='outline-secondary'
                                          checked={!clear}
                                          value='1'
                                          onClick={() => {
                                            ClearFilter();
                                          }}
                                          className='btn primary-bg-color-sp-secondary px-4 mx-4'
                                        >
                                          Clear
                                        </ToggleButton>
                                        <button
                                          type='button'
                                          onClick={() => {
                                            setOpen(() => !open);
                                          }}
                                          className='btn primary-bg-color-sp-ouline px-4 mx-4'
                                        >
                                          Close
                                        </button>
                                        <button
                                          type='button'
                                          onClick={() => {
                                            ApplyFilter();
                                          }}
                                          className='btn primary-bg-color-sp text-white'
                                        >
                                          Filter
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                </Card>
                              </div>
                            </Collapse>
                          </div>
                        </>
                      </div>
                      <div className='col-4 d-flex align-items-center justify-content-center'>
                        <ToggleButton
                          id='toggle-check'
                          type='checkbox'
                          variant='outline-secondary'
                          checked={!checked}
                          value='1'
                          onChange={(e) => {
                            if (
                              FilterArray.length > 0 ||
                              clientFilter !== '' ||
                              projectName !== '' ||
                              proposalDate !== '' ||
                              projectLead !== '' ||
                              proposalAmount !== '' ||
                              projectStatus !== '' ||
                              projectTableStatus !== ''
                            ) {
                              ClearFilter();
                            }
                            setChecked(!checked);
                          }}
                        >
                          My Projects
                        </ToggleButton>
                      </div>
                    </div>
                  </div>
                  <div className='col-4 d-flex align-items-center justify-content-end'>
                    <div className='row justify-content-end'>
                      <div className='d-flex justify-content-end'>
                        <button
                          type='button'
                          className='btn primary-bg-color-sp text-white'
                          onClick={() => setModalShow(true)}
                        >
                          New Project
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!projects ? (
                <Spinner animation='border' />
              ) : (
                <Table responsive bordered hover size='sm' className='shadow-none'>
                  <thead className='shadow-none table-border'>
                    <tr>
                      <th>
                        <div className='d-flex justify-content-between'>
                          <div> PID </div>
                          {/* <div className='ShortButtonTH'>
                            <i
                              className='fa fa-solid fa-sort'
                              onClick={() => {
                                SetSortBtnValue1(() => !ActiveSortBtnValue1);
                                if (!ActiveSortBtnValue1) {
                                  SetSortWith((pre) => 'projectId');
                                  if (sortBy === 'dec') {
                                    SetSortBy((pre) => 'asc');
                                  } else {
                                    SetSortBy((pre) => 'dec');
                                  }
                                } else {
                                  SetSortWith((pre) => 'projectId');
                                  if (sortBy === 'dec') {
                                    SetSortBy((pre) => 'asc');
                                  } else {
                                    SetSortBy((pre) => 'dec');
                                  }
                                }
                              }}
                            ></i>
                          </div> */}
                        </div>
                      </th>
                      <th>
                        <div className='d-flex justify-content-between'>
                          <div> Project Name </div>
                          {/* <div className='ShortButtonTH'>
                            <i
                              className='fa fa-solid fa-sort'
                              onClick={() => {
                                SetSortBtnValue2(() => !ActiveSortBtnValue2);
                                if (!ActiveSortBtnValue2) {
                                  SetSortWith('projectName');
                                  getProjects(sessionStorage.getItem('accessToken'));
                                  if (sortBy === 'dec') {
                                    SetSortBy((pre) => 'asc');
                                  } else {
                                    SetSortBy((pre) => 'dec');
                                  }
                                } else {
                                  SetSortWith('projectName');
                                  getProjects(sessionStorage.getItem('accessToken'));
                                  if (sortBy === 'dec') {
                                    SetSortBy((pre) => 'asc');
                                  } else {
                                    SetSortBy((pre) => 'dec');
                                  }
                                }
                              }}
                            ></i>
                          </div> */}
                        </div>
                      </th>
                      <th>Action</th>
                      <th>
                        <div className='d-flex justify-content-between'>
                          <div> Status</div>
                          {/* <div className='ShortButtonTH'>
                            <i
                              className='fa fa-solid fa-sort'
                              onClick={() => {
                                SetSortBtnValue3(() => !ActiveSortBtnValue3);
                                if (!ActiveSortBtnValue3) {
                                  SetSortWith((pre) => 'status');
                                  if (sortBy === 'dec') {
                                    SetSortBy((pre) => 'asc');
                                  } else {
                                    SetSortBy((pre) => 'dec');
                                  }
                                  getProjects(sessionStorage.getItem('accessToken'));
                                } else {
                                  SetSortWith((pre) => 'status');
                                  if (sortBy === 'dec') {
                                    SetSortBy((pre) => 'asc');
                                  } else {
                                    SetSortBy((pre) => 'dec');
                                  }
                                  getProjects(sessionStorage.getItem('accessToken'));
                                }
                              }}
                            ></i>
                          </div> */}
                        </div>
                      </th>
                      <th>
                        <div className='d-flex justify-content-between'>
                          <div> Project Lead</div>
                          {/* <div className='ShortButtonTH'>
                            <i
                              className='fa fa-solid fa-sort'
                              onClick={() => {
                                SetSortBtnValue4(() => !ActiveSortBtnValue4);
                                if (!ActiveSortBtnValue4) {
                                  SetSortWith((pre) => 'projectLead');
                                  if (sortBy === 'dec') {
                                    SetSortBy((pre) => 'asc');
                                  } else {
                                    SetSortBy((pre) => 'dec');
                                  }
                                  getProjects(sessionStorage.getItem('accessToken'));
                                } else {
                                  SetSortWith((pre) => 'projectLead');
                                  if (sortBy === 'dec') {
                                    SetSortBy((pre) => 'asc');
                                  } else {
                                    SetSortBy((pre) => 'dec');
                                  }
                                  getProjects(sessionStorage.getItem('accessToken'));
                                }
                              }}
                            ></i>
                          </div> */}
                        </div>
                      </th>
                      <th>
                        <div
                          style={{ minWidth: '135px' }}
                          className='d-flex justify-content-between'
                        >
                          <div> Overall Category </div>
                          {/* <div className='ShortButtonTH'>
                            <i
                              className='fa fa-solid fa-sort'
                              onClick={() => {
                                SetSortBtnValue5(() => !ActiveSortBtnValue5);
                                if (!ActiveSortBtnValue5) {
                                  SetSortWith((pre) => 'overallCategory');
                                  if (sortBy === 'dec') {
                                    SetSortBy((pre) => 'asc');
                                  } else {
                                    SetSortBy((pre) => 'dec');
                                  }
                                  getProjects(sessionStorage.getItem('accessToken'));
                                } else {
                                  SetSortWith((pre) => 'overallCategory');
                                  if (sortBy === 'dec') {
                                    SetSortBy((pre) => 'asc');
                                  } else {
                                    SetSortBy((pre) => 'dec');
                                  }
                                  getProjects(sessionStorage.getItem('accessToken'));
                                }
                              }}
                            ></i>
                          </div> */}
                        </div>
                      </th>
                      <th>
                        <div className='d-flex justify-content-between'>
                          <div> Client </div>
                          {/* <div className='ShortButtonTH'>
                            <i
                              className='fa fa-solid fa-sort'
                              onClick={() => {
                                SetSortBtnValue6(() => !ActiveSortBtnValue6);
                                if (!ActiveSortBtnValue6) {
                                  SetSortWith((pre) => 'clientName');
                                  if (sortBy === 'dec') {
                                    SetSortBy((pre) => 'asc');
                                  } else {
                                    SetSortBy((pre) => 'dec');
                                  }
                                  getProjects(sessionStorage.getItem('accessToken'));
                                } else {
                                  SetSortWith((pre) => 'clientName');
                                  if (sortBy === 'dec') {
                                    SetSortBy((pre) => 'asc');
                                  } else {
                                    SetSortBy((pre) => 'dec');
                                  }
                                  getProjects(sessionStorage.getItem('accessToken'));
                                }
                              }}
                            ></i>
                          </div> */}
                        </div>
                      </th>
                      <th>
                        <div
                          style={{ minWidth: '144px' }}
                          className='d-flex justify-content-between'
                        >
                          <div> Proposal Amount </div>
                        </div>
                      </th>

                      <th>
                        <div
                          style={{ minWidth: '115px' }}
                          className='d-flex justify-content-between'
                        >
                          <div> Proposal Date </div>
                          {/* <div className='ShortButtonTH'>
                            <i
                              className='fa fa-solid fa-sort'
                              onClick={() => {
                                SetSortBtnValue8(() => !ActiveSortBtnValue8);
                                if (!ActiveSortBtnValue7) {
                                  SetSortWith((pre) => 'proposalDate');
                                  if (sortBy === 'dec') {
                                    SetSortBy((pre) => 'asc');
                                  } else {
                                    SetSortBy((pre) => 'dec');
                                  }
                                  getProjects(sessionStorage.getItem('accessToken'));
                                } else {
                                  SetSortWith((pre) => 'proposalDate');
                                  if (sortBy === 'dec') {
                                    SetSortBy((pre) => 'asc');
                                  } else {
                                    SetSortBy((pre) => 'dec');
                                  }
                                  getProjects(sessionStorage.getItem('accessToken'));
                                }
                              }}
                              ></i>
                            </div> */}
                        </div>
                      </th>
                      <th>
                        <div
                          style={{ minWidth: '144px' }}
                          className='d-flex justify-content-between'
                        >
                          <div>Amount Billed</div>
                        </div>
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className='table-border'>
                    {projects?.map((value, index) => (
                      <tr key={index}>
                        <td
                          className='align-middle px-1'
                          onClick={() => {
                            handleSetReportModalShow();
                            setRowValue(value);
                          }}
                        >
                          {value.projectId}
                        </td>
                        {/* <td className='position-relative' style={{ maxWidth: '20vw' }}> */}
                        <td className='align-middle px-1'>
                          <div
                            className='d-flex justify-content-between'
                            onClick={() => {
                              handleSetReportModalShow();
                              setRowValue(value);
                            }}
                          >
                            <div>
                              {/* <div style={{ width: '80%' }}> */}
                              <span>{value.projectName}</span>
                            </div>
                            {/* <div style={{ textAlign: 'right' }}> */}
                            {/* <div style={{ textAlign: "right", width: '20%'}}> */}
                            {/* <span data-bs-toggle='tooltip' data-bs-placement='top'>
                              <svg
                                onClick={() => handleSample(value.projectId)}
                                role='button'
                                width='18'
                                height='18'
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 384 512'
                                fill='#2B9191'
                                className='mx-1'
                              >
                                <path d='M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM80 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm16 96H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V256c0-17.7 14.3-32 32-32zm0 32v64H288V256H96zM240 416h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H240c-8.8 0-16-7.2-16-16s7.2-16 16-16z' />
                              </svg>
                            </span> */}
                            {/* <span data-bs-toggle='tooltip' data-bs-placement='top' title='Notes'> */}
                            {/* <svg
                              role='button'
                              xmlns='http://www.w3.org/2000/svg'
                              width='18'
                              height='18'
                              viewBox='0 0 13.835 13.835'
                              onClick={() => handleComment(value.projectId)}
                            >
                              <g
                                id='Group_702'
                                data-name='Group 702'
                                transform='translate(-409 -429.164)'
                              >
                                <g
                                  id='Group_702-2'
                                  data-name='Group 702'
                                  transform='translate(2 0.576)'
                                >
                                  <path
                                    id='comment'
                                    d='M6.918,12.106a7.881,7.881,0,0,1-1.81-.216,7.693,7.693,0,0,1-.939.939,6.011,6.011,0,0,1-.811.588,3.471,3.471,0,0,1-.642.291,3,3,0,0,1-.412.115,1.259,1.259,0,0,1-.142.013,4.687,4.687,0,0,0,.405-1.682,3.619,3.619,0,0,0-.189-1.533A6.293,6.293,0,0,1,.635,8.579,5.334,5.334,0,0,1,.547,3.7,6.092,6.092,0,0,1,2.02,1.77,7.075,7.075,0,0,1,4.229.48a7.776,7.776,0,0,1,5.377,0,7.059,7.059,0,0,1,2.209,1.29A6.1,6.1,0,0,1,13.288,3.7a5.323,5.323,0,0,1,0,4.7,6.1,6.1,0,0,1-1.473,1.932,7.071,7.071,0,0,1-2.209,1.291A7.607,7.607,0,0,1,6.918,12.106Z'
                                    transform='translate(407 428.588)'
                                    fill='#2B9191'
                                  />
                                  <path
                                    id='plus-round'
                                    d='M9.4,6.718H7.605V4.924a.444.444,0,0,0-.887,0V6.718H4.924a.444.444,0,0,0,0,.887H6.718V9.4a.444.444,0,0,0,.887,0V7.605H9.4a.444.444,0,0,0,0-.887Z'
                                    transform='translate(406.756 427.603)'
                                    fill='#fff'
                                  />
                                </g>
                              </g>
                            </svg> */}
                            {/* </span> */}
                            {/* </div> */}
                          </div>
                        </td>
                        <td className='text-center align-middle px-1'>
                          <Button
                            variant='outline-dark'
                            size='sm'
                            onClick={() => {
                              handleSample(value.projectId, value.clientId);
                            }}
                          >
                            Samples
                          </Button>
                        </td>
                        <td className='align-middle'>
                          <select
                            className={getColorClass(value.status)}
                            title='Select-Status'
                            id='status'
                            name='status'
                            value={value.status}
                            onChange={(e) => {
                              valueRef.current = e.target.value;
                              setProjectId(value.projectId);
                              setSendEmailState(true);
                              classNameRef.current = getColorClass(e.target.value);
                              changeStatus(value.projectId, e.target.value);
                            }}
                          >
                            <option value='Proposal'>Proposal</option>
                            <option value='Planning/Execution'>Planning/Execution</option>
                            <option value='Not Accepted/Cancelled'>Not Accepted/Cancelled</option>
                            <option value='Completed'>Completed</option>
                          </select>
                        </td>
                        <td
                          className='align-middle px-1'
                          onClick={() => {
                            handleSetReportModalShow();
                            setRowValue(value);
                          }}
                        >
                          {value.projectLead}
                        </td>
                        <td
                          className='align-middle px-1'
                          onClick={() => {
                            handleSetReportModalShow();
                            setRowValue(value);
                          }}
                        >
                          {value.overallCategory}
                        </td>
                        {/* <td style={{ width: '15%', wordWrap: 'break-word' }}>
                        {value.employeeId?.map((emp: any) => (
                          <span>{employeeData?.find((x) => x.id === emp) === undefined ? '' : employeeData?.find((x) => x.id === emp).displayName + ', '}</span>
                        ))}
                      </td> */}

                        <td
                          className='align-middle px-1'
                          onClick={() => {
                            handleSetReportModalShow();
                            setRowValue(value);
                          }}
                        >
                          {value.clientName}
                          {/* {clientList?.find((x) => x.clientId === value.clientId) === undefined
                          ? ''
                          : clientList?.find((x) => x.clientId === value.clientId).clientName} */}
                        </td>
                        {/* <td>
                        {contactList?.find((x) => x.contactId === value.contactId) === undefined ? '' : contactList?.find((x) => x.contactId === value.contactId).firstName}
                      </td> */}
                        <td
                          className='align-middle px-1'
                          onClick={() => {
                            handleSetReportModalShow();
                            setRowValue(value);
                          }}
                        >
                          {value.proposalAmount === null
                            ? ''
                            : value.proposalAmount.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD'
                              })}
                        </td>
                        <td
                          className='align-middle px-1 text-nowrap'
                          onClick={() => {
                            handleSetReportModalShow();
                            setRowValue(value);
                          }}
                        >
                          {new Date(value.proposalDate).toDateString()}
                        </td>
                        <td
                          className='align-middle px-1'
                          onClick={() => {
                            handleSetReportModalShow();
                            setRowValue(value);
                          }}
                        >
                          {value.totalBilled === null
                            ? ''
                            : value.totalBilled.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD'
                              })}
                        </td>
                        {/* <td>{value.requiresTravel ? 'Yes' : 'No'}</td> */}
                        {/* <td style={{ textAlign: 'center' }}> */}
                        <td className='text-center align-middle px-1'>
                          <div className='d-flex justify-content-center'>
                            <span data-bs-toggle='tooltip' data-bs-placement='right' title='Edit'>
                              <svg
                                role='button'
                                className='action-btn action-btn-edit mx-2'
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 512 512'
                                onClick={() => editItem(value.projectId)}
                              >
                                <path d='M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z' />
                              </svg>
                            </span>
                            <span
                              data-bs-toggle='tooltip'
                              data-bs-placement='right'
                              title='Add Note'
                            >
                              <svg
                                role='button'
                                xmlns='http://www.w3.org/2000/svg'
                                width='18'
                                height='18'
                                viewBox='0 0 13.835 13.835'
                                onClick={() => handleComment(value.projectId, true)}
                              >
                                <g
                                  id='Group_702'
                                  data-name='Group 702'
                                  transform='translate(-409 -429.164)'
                                >
                                  <g
                                    id='Group_702-2'
                                    data-name='Group 702'
                                    transform='translate(2 0.576)'
                                  >
                                    <path
                                      id='comment'
                                      d='M6.918,12.106a7.881,7.881,0,0,1-1.81-.216,7.693,7.693,0,0,1-.939.939,6.011,6.011,0,0,1-.811.588,3.471,3.471,0,0,1-.642.291,3,3,0,0,1-.412.115,1.259,1.259,0,0,1-.142.013,4.687,4.687,0,0,0,.405-1.682,3.619,3.619,0,0,0-.189-1.533A6.293,6.293,0,0,1,.635,8.579,5.334,5.334,0,0,1,.547,3.7,6.092,6.092,0,0,1,2.02,1.77,7.075,7.075,0,0,1,4.229.48a7.776,7.776,0,0,1,5.377,0,7.059,7.059,0,0,1,2.209,1.29A6.1,6.1,0,0,1,13.288,3.7a5.323,5.323,0,0,1,0,4.7,6.1,6.1,0,0,1-1.473,1.932,7.071,7.071,0,0,1-2.209,1.291A7.607,7.607,0,0,1,6.918,12.106Z'
                                      transform='translate(407 428.588)'
                                      fill='#2B9191'
                                    />
                                    <path
                                      id='plus-round'
                                      d='M9.4,6.718H7.605V4.924a.444.444,0,0,0-.887,0V6.718H4.924a.444.444,0,0,0,0,.887H6.718V9.4a.444.444,0,0,0,.887,0V7.605H9.4a.444.444,0,0,0,0-.887Z'
                                      transform='translate(406.756 427.603)'
                                      fill='#fff'
                                    />
                                  </g>
                                </g>
                              </svg>
                            </span>
                            {/* <span data-bs-toggle='tooltip' data-bs-placement='right' title='Delete'>
                          <svg
                            role='button'
                            onClick={() => deleteItem(value.projectId)}
                            className='action-btn action-btn-delete'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 448 512'
                          >
                            <path d='M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z' />
                          </svg>
                        </span> */}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
              <Pagination
                pageSize={PageSize}
                currentPage={page}
                totalAmountOfPages={totalAmountOfPages}
                onChange={(newPage) => setPage(newPage)}
              />
              <div className='col-12 d-flex align-items-center justify-content-end'>
                <TableNumberSelect handleRecordsPerPage={HandleRecordsPerPage} />
              </div>
            </div>

            <DialogModel
              name={modalTitle}
              show={modalShow}
              projectId={projectId}
              handleEditModalShow={handleEditModalShow}
              employeeData={employeeData}
              handleToast={handleToast}
              onHide={() => {
                setModalShow(false);
                setModalTitle('Add New Project');
                setProjectId(undefined);
                getProjects(sessionStorage.getItem('accessToken'));
              }}
            />
            <CommentModel
              show={commentModalShow}
              handleCommentModalShow={handleCommentModalShow}
              projectId={projectId}
              handleToast={handleToast}
              onHide={() => {
                setCommentModalShow(false);
                setProjectId(undefined);
                getProjects(sessionStorage.getItem('accessToken'));
              }}
            />
            <SampleModel
              show={sampleModalShow}
              handleSampleModalShow={handleSampleModalShow}
              clearProjectId={clearProjectId}
              // projectId={clientList?.find((x) => x.clientId === projectId) === undefined ? '' : clientList?.find((x) => x.clientId === projectId).clientId}
              handleToast={handleToast}
              sampleModel={sampleModel}
              userEmail={userEmail}
              handleSampleModel={handleSampleModel}
              projectId={projectId}
              clientId={clientId}
              sampleEmail={sampleEmail}
              setSampleEmail={setSampleEmail}
              sendEmail={sendEmail}
              projects={projects}
              getProjects={getProjects}
              onShow={() => {
                getProjects(sessionStorage.getItem('accessToken'));
              }}
              employeeData={employeeData}
              // getMSGraphUserList={getMSGraphUserList}
              name={name}
              onHide={() => {
                setSampleModalShow(false);
                setProjectId(undefined);
                getProjects(sessionStorage.getItem('accessToken'));
              }}
            />
            {reportModalShow && (
              <ReportModal
                show={reportModalShow}
                handleReportModalClose={handleSetReportModalShow}
                value={rowValue}
              />
            )}
            <ToastContainer className='p-3' position='top-center'>
              <Toast
                bg={toastType}
                onClose={() => {
                  setToastShow(false);
                  setToastType('');
                  setToastHeaderMessage('');
                  setToastMessage('');
                }}
                show={toastShow}
                delay={3000}
                autohide
              >
                <Toast.Header closeButton={true}>
                  <strong className='me-auto'>{toastHeaderMessage}</strong>
                </Toast.Header>
                <Toast.Body>{toastMessage}</Toast.Body>
              </Toast>
            </ToastContainer>
          </div>
        </div>
      </div>
    </>
  );
}
