import React, { useState, useRef, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import styles from './businessClassTable.module.css';
import BusinessClassTableModal from '../BusinessClassTableModal/BusinessClassTableModal';
import axios from 'axios';
import { urlDashboard } from '../../../../endpoints';
import { set } from 'react-hook-form';

function BusinessClassTable(props: any) {
  const [modalShow, setModalShow] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(0);
  const [responseModel, setResponseModel] = useState([]);
  const [selectedBusinessClass, setSelectedBusinessClass] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const innerTextRefs = useRef<any>([]); // Use an array to store refs for each cell
  // const { dashboard } = props.dashboard;
  // const { BusinessClassByMonth } = props.BusinessClassByMonth;

  const currentYear = new Date().getFullYear().toString();

  useEffect(() => {
    setSelectedYear(currentYear);
  }, []);

  useEffect(() => {
    if (responseModel.length > 0) setModalShow(true);
  }, [responseModel]);

  const handleCellClick = (businessClass: string, month: number, selectedYear: string) => {
    setSelectedBusinessClass(businessClass);
    setSelectedMonth(month);
    getProposalsByBillingCenter(businessClass, month, selectedYear);
  };

  const handleBusinessClassByMonth = (selectedYear: string) => {
    props.GetBusinessClassByMonth(selectedYear);
  };

  const formatCurrency = (amount: any) =>
    amount !== 0 && amount
      ? amount.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0
        })
      : '-';

  function reset() {
    setSelectedBusinessClass('');
    setSelectedMonth(0);
    setResponseModel([]);
  }

  function getProposalsByBillingCenter(
    selectedBusinessClass: string,
    selectedMonth: number,
    selectedYear?: string
  ) {
    const encodedBusinessComponent = encodeURIComponent(selectedBusinessClass);
    axios
      .get(
        `${urlDashboard}/GetProposalsByBillingCenter?billingCenter=${encodedBusinessComponent}&month=${selectedMonth}&selectedYear=${selectedYear}`,
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` }
        }
      )
      .then((response) => {
        console.log(response.data);
        setResponseModel(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return props.dashboard ? (
    <>
      <table className={styles.businessClassTable}>
        <thead>
          <tr>
            {/* <th className='d-flex justify-content-evenly'> */}
            <th>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  whiteSpace: 'nowrap'
                }}
              >
                <span>Business Class</span>
                <Form.Select
                  title='Select Year'
                  onChange={(e) => {
                    setSelectedYear(e.target.value.toString());
                    handleBusinessClassByMonth(e.target.value);
                  }}
                  // style={{ marginLeft: '30px', marginRight: '0px', width: '100px' }}
                  style={{ width: '100px' }}
                >
                  <option value='2025' selected>
                    2025
                  </option>
                  <option value='2024'>2024</option>
                  <option value='2023'>2023</option>
                  <option value='2022'>2022</option>
                </Form.Select>
              </div>
            </th>

            {Array.from({ length: 12 }, (_, i) => (
              <th key={i}>
                {
                  [
                    'Jan',
                    'Feb',
                    'Mar',
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec'
                  ][i]
                }
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.billingClassTable.finalData?.map((billingCenter: any, index: number) => (
            <tr key={index}>
              <td>{billingCenter.billingCenter}</td>
              {Array.from({ length: 12 }, (_, i) => (
                <td
                  className={styles.td}
                  onClick={() => {
                    const innerTextValue = innerTextRefs.current[index]?.[i]?.innerText;
                    if (innerTextValue !== '-') {
                      handleCellClick(billingCenter.billingCenter, i, selectedYear);
                    } else {
                      return;
                    }
                  }}
                  key={i}
                >
                  <span
                    ref={(el) =>
                      innerTextRefs.current[index]
                        ? (innerTextRefs.current[index][i] = el)
                        : (innerTextRefs.current[index] = [el])
                    }
                  >
                    {formatCurrency(props.billingClassTable.finalData[index].totalAmount[i])}
                  </span>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <BusinessClassTableModal
        show={modalShow}
        month={selectedMonth}
        businessClass={selectedBusinessClass}
        responseModal={responseModel}
        onHide={() => {
          setModalShow(false);
          setResponseModel([]);
          reset();
        }}
        //onShow={() => getProposalsByBillingCenter(selectedBusinessClass, selectedMonth)}
      />
    </>
  ) : null;
}
export default BusinessClassTable;
