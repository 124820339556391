import { useEffect, useState, FormEvent, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './dialogmodel.css';
import axios from 'axios';
import { urlProjects, urlContacts, urlEmployee, urlClients } from '../../../endpoints';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { loginRequest } from '../../../auth/authConfig';
import { useMsal } from '@azure/msal-react';
import Multiselect from 'multiselect-react-dropdown';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input-field';
import { FieldError, useForm } from 'react-hook-form';
import useNewProjectValidateAll from '../../../components/Validation/ProjectValidations/useNewProjectValidateAll';
import useNewProjectValidateEdit from '../../../components/Validation/ProjectValidations/useNewProjectValidateEdit';
import useHandleProjectType from '../../../hooks/useHandleProjectType';
import { ChevronDoubleLeft } from 'heroicons-react';
import { useGetClients, useClientList } from '../../../context/ClientContext';
import { useGetUserList, useUserList } from '../../../context/UserContext';
import { useGetContacts, useContactList } from '../../../context/ContactContext';
import useExclusionList from '../../../hooks/useExclusionList';
import exp from 'constants';

function DialogModel(props: any) {
  const [model, setModel] = useState({
    projectId: '',
    projectName: '',
    status: '',
    serviceType: '',
    projectLead: '',
    overallCategory: '',
    proposalDate: '',
    proposalAmount: null,
    requiresTravel: false,
    contactId: null,
    clientId: '',
    employeeId: [],
    exploratory: false
  } as any);

  const [validationState, setValidationState] = useState(false);
  const [editValidationState, setEditValidationState] = useState(false);
  const [projectStatus, setProjectStatus] = useState();
  const [defaultEmail, setDefaultEmail] = useState('JoelNelson@qbsr.onmicrosoft.com');
  const [email, setEmail] = useState();

  //const [contactList, setContactList] = useState<any[]>([]);
  // const [clientList, setClientList] = useState<any[]>([]);
  //const [employeeList, setEmployeeList] = useState<any[]>([]);

  const [show, setShow] = useState(props.show);

  const [response, setResponse] = useState({
    status: false,
    message: ''
  });

  const [employeeData, setEmployeeData] = useState<any[]>([]);
  const [employeeOption, setEmployeeOption] = useState<any[]>([]);
  const [selectedEmployee, setSelectEmployee] = useState<any[]>([]);
  const [originalEmployeeIds, setOriginalEmployeeIds] = useState<any[]>([]);
  //const [selectedOption, setSelectedOption] = useState<any>(null);
  const { instance, accounts } = useMsal();
  const [isChecked, setIsChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [className, setClassName] = useState('');
  const [rawValue, setRawValue] = useState<string | undefined>(' ');
  const [disableMultiSelect, setDisableMultiSelect] = useState(false);
  const { validated, ValidateAll, validate, resetValidations } = useNewProjectValidateAll();
  const { editValidated, EditValidateAll, editValidate, editResetValidations } =
    useNewProjectValidateEdit();

  const clientList = useClientList();
  const getClients = useGetClients();
  const employeeList = useUserList();
  const getEmployee = useGetUserList();
  const getContacts = useGetContacts();
  const contactList = useContactList();
  // const getUsers: () => Promise<void> = useGetUserList();

  const disallowedDisplayNames: any[] = useExclusionList();
  const {
    handleProjectType,
    selectedOption,
    setSelectedOption,
    handlePayload,
    ClearPayload,
    projectTypePayload
  } = useHandleProjectType(props);
  // const projectTypePayload = useHandleProjectType(props).projectTypePayload;
  // const selectedOption = useHandleProjectType(props).selectedOption;
  // const setSelectedOption = useHandleProjectType(props).setSelectedOption;

  useEffect(() => {
    if (props.show === true) {
      getContacts();
      //getUsers();
    }
    // getClients();
    // getEmployee();
  }, [props.show]);

  useEffect(() => {
    if (
      projectTypePayload !== '' &&
      projectTypePayload !== undefined &&
      projectTypePayload !== null
    ) {
      const x = { ...model };
      x.projectType = projectTypePayload;
      setModel(x);
    }
  }, [projectTypePayload]);

  useEffect(() => {
    handleSetEmployeeOption(employeeList, disallowedDisplayNames);
    if (props.projectId !== undefined && props.show === true) {
      axios
        .get(`${urlProjects}/${props.projectId}`, {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` }
        })
        .then((res) => {
          if (res.data.status === true) {
            var data = res.data.data;
            handleProjectType(data.projectType);
            const projectType = projectTypePayload;
            const x = { ...model };
            x.projectId = data.projectId;
            x.projectName = data.projectName;
            x.status = data.status;
            x.serviceType = data.serviceType;
            x.projectLead = data.projectLead;
            x.overallCategory = data.overallCategory;
            x.clientId = data.clientId;
            x.clientName = data.clientName;
            x.proposalDate =
              data.proposalDate != null ? data.proposalDate.split('T')[0] : data.proposalDate;
            x.proposalAmount = data.proposalAmount;
            x.requiresTravel = data.requiresTravel;
            x.contactId = data.contactId;
            x.employeeId = data.employeeId;
            x.billingCenter = data.billingCenter;
            //x.projectType = data.projectType;
            x.projectType = projectType;
            x.panelType = data.panelType;
            x.exploratory = data.exploratory;

            setModel(x);
            setIsChecked(data.exploratory);
            //setContactList(res.data.data.contactList);
            setProjectStatus(res.data.data.status);
            getSelectedEmployeeIds(data.employeeId, false);
            setOriginalEmployeeIds(data.employeeId);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const x = { ...model };
      x.projectId = '';
      x.projectName = '';
      x.status = '';
      x.serviceType = '';
      x.projectLead = '';
      x.overallCategory = '';
      x.clientId = '';
      x.clientName = '';
      x.proposalDate = '';
      x.proposalAmount = null;
      x.requiresTravel = false;
      x.contactId = null;
      x.employeeId = [];
      x.billingCenter = '';
      x.projectType = '';
      x.panelType = '';
      x.exploratory = false;
      ClearPayload();
      setModel(x);
    }
  }, [props, props.projectId]);

  // function handleProjectType(projectType: any) {
  //   if (projectType !== '' && projectType !== undefined && projectType !== null) {
  //     let obj = {} as any;
  //     let arr = [] as any;
  //     //add the selected option to the object
  //     obj['value'] = projectType;
  //     obj['label'] = projectType;
  //     arr.push(obj);
  //     setSelectedOption(arr);
  //     props.handleEditModalShow(true);
  //   } else {
  //     setSelectedOption(null);
  //     props.handleEditModalShow(true);
  //   }
  // }

  // useEffect(() => {
  //   if (model.projectType !== '' && model.projectType !== undefined) {
  //     let obj = {} as any;
  //     let arr = [] as any;
  //     //add the selected option to the object
  //     obj['value'] = model.projectType;
  //     obj['label'] = model.projectType;
  //     arr.push(obj);
  //     setSelectedOption(arr);
  //   } else {
  //     setSelectedOption('');
  //   }
  // }, [model.projectType]);

  // useEffect(() => {
  //   props.handleEditModalShow(true);
  // }, [selectedOption]);

  function sendEmail(token: any) {
    var employeeEmailsBody = [];
    for (let i = 0; i < model.employeeId.length; i++) {
      employeeEmailsBody.push({
        emailAddress: {
          address:
            props.employeeData?.find((x: any) => x.id === model.employeeId[i]) === undefined
              ? null
              : props.employeeData?.find((x: any) => x.id === model.employeeId[i]).userPrincipalName
        }
      });
    }

    var body = {
      message: {
        subject: `${model.ProjectId} Project Status Changed`,
        body: {
          contentType: 'Text',
          content: `Project: ${model.ProjectId} ${model.projectName} status has been changed to ${model.status}`
        },
        toRecipients: employeeEmailsBody
      },
      saveToSentItems: 'true'
    };

    var payload = JSON.stringify(body);
    axios
      //.post(`https://graph.microsoft.com/v1.0/users/${defaultEmail}/sendMail`, payload, {
      .post(`https://graph.microsoft.com/v1.0/me/sendMail`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      .then((res: any) => {
        if (res.status === 202) {
          setModel({
            projectId: '',
            clientId: '',
            projectName: '',
            status: '',
            serviceType: '',
            projectLead: '',
            overallCategory: '',
            clientName: '',
            proposalDate: '',
            proposalAmount: 0,
            requiresTravel: false,
            contactId: null,
            billingCenter: '',
            projectType: '',
            panelType: '',
            employeeId: [],
            exploratory: false
          });
        }
      })
      .catch((error: any) => {
        console.error(error);
      });
  }

  function handleSetEmployeeOption(data: any, disallowedDisplayNames: any[]) {
    var employeeOptn = data.filter(function (item: any) {
      return !disallowedDisplayNames.includes(item.displayName);
    });
    setEmployeeOption(employeeOptn);
  }

  function getSelectedEmployeeIds(data: any, from: any) {
    var selectedEmp = [];
    for (let i = 0; i < data.length; i++) {
      if (from === true) {
        selectedEmp.push(employeeOption.find((x) => x.id === data[i].id));
        const c = { ...model };
        c.employeeId = selectedEmp;
        setModel(c);
      } else {
        selectedEmp.push(employeeOption.find((x) => x.id === data[i]));
      }
    }
    setSelectEmployee(selectedEmp);
  }

  function deleteTeamMember(selectedItems: any[]) {
    const originalEmployeeIdsArray = originalEmployeeIds.map((item: any, index: any) => {
      const { id } = item;
      return { id: index, item };
    });
    const newSelectedItemsArray = selectedItems.map((item: any, index: any) => {
      const { id } = item;
      return { id: index, item: item.id };
    });
    let deletedItems = originalEmployeeIdsArray.filter(
      (element) => !newSelectedItemsArray.some((item) => item.item === element.item)
    );
    axios
      .delete(`${urlProjects}/DeleteTeamMember/${props.projectId}/${deletedItems[0].item}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` }
      })
      .then((res) => {
        if (res.data.status === true) {
          // console.log(res.data.data);
          setDisableMultiSelect(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (disableMultiSelect === true) {
      setTimeout(() => {
        setDisableMultiSelect(false);
      }, 1500);
    }
  }, [disableMultiSelect]);

  function handleSubmit() {
    if (props.name === 'Add New Project') {
      ValidateAll();
    }
    if (props.name === 'Edit Project') {
      EditValidateAll();
    }
  }

  useEffect(() => {
    setValidationState(validated);
  }, [validated]);

  useEffect(() => {
    setEditValidationState(editValidated);
  }, [editValidated]);

  useEffect(() => {
    if (editValidationState === true) {
      submit();
      editResetValidations();
    }
    if (validationState === true) {
      submit();
      resetValidations();
    }
  }, [validationState, editValidationState]);

  function submit() {
    // if (projectFormValidation.validated === true || editValidated === true) {
    if (editValidationState === true || validationState === true) {
      var employeeIdList: any[] = [];
      for (let x = 0; x < model.employeeId.length; x++) {
        employeeIdList.push(
          model.employeeId[x].id === undefined ? model.employeeId[x] : model.employeeId[x].id
        );
      }
      // Create a new array to store the filtered employee IDs
      const filteredEmployeeIds = employeeIdList.filter((id) => !originalEmployeeIds.includes(id));
      // Update employeeIdList with the filtered array
      employeeIdList = filteredEmployeeIds;

      var payload = JSON.stringify({
        projectId: model.projectId,
        projectName: model.projectName,
        status: model.status,
        clientId: model.clientId,
        serviceType: model.serviceType,
        projectLead: model.projectLead,
        overallCategory: model.overallCategory,
        proposalDate: model.proposalDate,
        proposalAmount: model.proposalAmount,
        clientName: model.clientName,
        requiresTravel: model.requiresTravel,
        contactId: model.contactId,
        billingCenter: model.billingCenter,
        projectType: model.projectType,
        panelType: model.panelType,
        employeeId: employeeIdList,
        exploratory: isChecked
      });

      if (model.projectId !== undefined && model.projectId !== '') {
        axios
          .put(`${urlProjects}`, payload, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
            }
          })
          .then((res: any) => {
            if (res.data.status) {
              props.onHide(false);
              setModel({
                projectId: '',
                projectName: '',
                status: '',
                projectLead: '',
                serviceType: '',
                overallCategory: '',
                proposalDate: '',
                proposalAmount: 0,
                clientName: '',
                clientId: '',
                requiresTravel: false,
                contactId: null,
                billingCenter: '',
                projectType: '',
                panelType: '',
                employeeId: [],
                exploratory: false
              });
              setSelectEmployee([]);
              props.handleToast(res.data.message);
            } else {
              const c = { ...response };
              c.status = res.data.status;
              c.message = res.data.message;
              setResponse(c);
              setShow(true);
            }
          })
          .catch((error) => {
            console.error(error);
          });

        if (projectStatus !== model.status) {
          RequestAccessTokenMail();
        } else {
          setModel({
            projectId: '',
            clientId: '',
            projectName: '',
            status: '',
            projectLead: '',
            serviceType: '',
            overallCategory: '',
            proposalDate: '',
            proposalAmount: 0,
            clientName: '',
            requiresTravel: false,
            contactId: null,
            billingCenter: '',
            projectType: '',
            panelType: '',
            employeeId: [],
            exploratory: false
          });
          setSelectEmployee([]);
        }
      } else {
        axios
          .post(`${urlProjects}`, payload, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
            }
          })
          .then((res: any) => {
            if (res.data.status) {
              props.onHide(false);
              setModel({
                projectId: '',
                clientId: '',
                projectName: '',
                status: '',
                projectLead: '',
                serviceType: '',
                overallCategory: '',
                proposalDate: '',
                proposalAmount: 0,
                clientName: '',
                requiresTravel: false,
                contactId: null,
                billingCenter: '',
                projectType: '',
                panelType: '',
                employeeId: [],
                exploratory: false
              });
              setSelectEmployee([]);
              props.handleToast(res.data.message);
            } else {
              const c = { ...response };
              c.status = res.data.status;
              c.message = res.data.message;
              setResponse(c);
              setShow(true);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }

  function resetModel() {
    props.onHide(false);
    setModel({
      projectId: '',
      clientId: '',
      projectName: '',
      status: '',
      projectLead: '',
      serviceType: '',
      overallCategory: '',
      proposalDate: '',
      proposalAmount: 0,
      clientName: '',
      requiresTravel: false,
      contactId: null,
      billingCenter: '',
      projectType: '',
      panelType: '',
      employeeId: []
    });
    setIsChecked(false);
    setEmployeeOption([]);
    setSelectEmployee([]);
  }

  function RequestAccessTokenMail() {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    return instance
      .acquireTokenSilent(request)
      .then((response: any) => {
        sendEmail(response.accessToken);
      })
      .catch((e) => {
        instance.acquireTokenPopup(request).then((response: any) => {
          sendEmail(response.accessToken);
        });
      });
  }

  const validateValue = (value: string | undefined): void => {
    const rawValue = value === undefined ? 'undefined' : value;
    setRawValue(rawValue || ' ');

    if (!value) {
      setClassName('');
    } else if (Number.isNaN(Number(value))) {
      setErrorMessage('Please enter a valid number');
      setClassName('is-invalid');
    } else {
      setClassName('is-valid');
      const x = { ...model };
      x.proposalAmount = Number(value);
      setModel(x);
    }
  };

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      backdrop='static'
      keyboard={false}
      centered
    >
      <Modal.Header className='primary-bg-color-sp text-white'>
        <Modal.Title id='contained-modal-title-vcenter' className='modeltitle'>
          {props.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='col-12'>
          <div className='row'>
            <div className='col-lg-6 col-sm-12'>
              <div className='mb-3'>
                <label htmlFor='projectName' className='form-label label-font'>
                  Project Name
                </label>
                <input
                  type='text'
                  formNoValidate={true}
                  className='form-control'
                  id='projectName'
                  name='projectName'
                  value={model.projectName}
                  // onBlur={() => {
                  //   projectFormValidation.validate('projectName');
                  // }}
                  onChange={(e) => {
                    const x = { ...model };
                    x.projectName = e.target.value;
                    setModel(x);
                    // validate();
                  }}
                />
                <div className='error'></div>
              </div>
            </div>
            <div className='col-lg-6 col-sm-12'>
              <div className='mb-3'>
                <label htmlFor='projectLead' className='form-label label-font'>
                  Project Lead
                </label>
                <select
                  title='projectLead'
                  className='form-select'
                  id='projectLead'
                  name='projectLead'
                  value={model.projectLead}
                  // onBlur={() => {
                  //   projectFormValidation.validate('projectLead');
                  // }}
                  onChange={(e) => {
                    const x = { ...model };
                    x.projectLead = e.target.value;
                    setModel(x);
                    // validate();
                  }}
                >
                  <option selected>Select...</option>
                  {employeeOption?.map((value, index) => (
                    <option key={index} value={value.displayName}>
                      {value.displayName}
                    </option>
                  ))}
                </select>
                <div className='error'></div>
              </div>
            </div>
            <div className='col-lg-3 col-sm-12'>
              <div className='mb-3'>
                <label htmlFor='statusSelect' className='form-label label-font'>
                  Status
                </label>
                <select
                  title='statusSelect'
                  className='form-select'
                  id='statusSelect'
                  name='statusSelect'
                  value={model.status}
                  defaultValue='Select Status'
                  // onBlur={() => {
                  //   projectFormValidation.validate('statusSelect');
                  // }}
                  onChange={(e) => {
                    const x = { ...model };
                    x.status = e.target.value;
                    setModel(x);
                  }}
                >
                  <option selected>Select...</option>
                  <option value='Proposal'>Proposal</option>
                  <option value='Planning/Execution'>Planning/Execution</option>
                  <option value='Not Accepted/Cancelled'>Not Accepted/Cancelled</option>
                  <option value='Completed'>Completed</option>
                </select>
                <div className='error'></div>
              </div>
            </div>
            <div className='col-lg-3 col-sm-12'>
              <div className='mb-3'>
                <label htmlFor='overallCategory' className='form-label label-font'>
                  Overall Category
                </label>
                <select
                  className='form-select'
                  id='overallCategory'
                  name='overallCategory'
                  value={model.overallCategory}
                  // onBlur={() => {
                  //   projectFormValidation.validate('overallCategory');
                  // }}
                  onChange={(e) => {
                    const x = { ...model };
                    x.overallCategory = e.target.value;
                    setModel(x);
                  }}
                >
                  <option selected>Select...</option>
                  <option value='Consulting'>Consulting</option>
                  <option value='Consumer Product'>Consumer + Product</option>
                  <option value='Consumer Understanding'>Consumer Understanding</option>
                  <option value='Data Analysis Or Reporting'>Data Analysis or Reporting</option>
                  <option value='Managed Services'>Managed Services</option>
                  <option value='Product Understanding'>Product Understanding</option>
                  <option value='Training and Education'>Training and Education</option>
                  <option value='Other'>Other</option>
                </select>
                <div className='error'></div>
              </div>
            </div>
            <div className='col-lg-3 col-sm-12'>
              <div className='mb-3'>
                <label htmlFor='clientIdForm' className='form-label label-font'>
                  Client
                </label>
                <select
                  title='clientIdForm'
                  className='form-select'
                  id='clientIdForm'
                  name='clientIdForm'
                  value={model.clientId}
                  // onBlur={() => {
                  //   projectFormValidation.validate('clientIdForm');
                  // }}
                  onChange={(e) => {
                    <div className='error'></div>;
                    const x = { ...model };
                    x.clientId = Number(e.target.value);
                    setModel(x);
                  }}
                >
                  <option selected>Select...</option>
                  {clientList?.map((value, index) => (
                    <option key={index} value={value.clientId}>
                      {value.clientName}
                    </option>
                  ))}
                </select>
                <div className='error'></div>
              </div>
            </div>
            <div className='col-lg-3 col-sm-12'>
              <div className='mb-3'>
                <label htmlFor='contactIdForm' className='form-label label-font'>
                  Contact
                </label>
                <select
                  title='contactIdForm'
                  className='form-select'
                  id='contactIdForm'
                  name='contactIdForm'
                  value={model.contactId}
                  // value={`${null}`}
                  // onBlur={() => {
                  //   projectFormValidation.validate('clientIdForm');
                  // }}
                  onChange={(e) => {
                    <div className='error'></div>;
                    const x = { ...model };
                    x.contactId = Number(e.target.value);
                    setModel(x);
                  }}
                >
                  <option selected>Select...</option>
                  {contactList
                    ?.filter((x) => x.clientId === model.clientId)
                    .map((value, index) => (
                      <option key={index} value={value.contactId}>
                        {value.firstName + ' ' + value.lastName}
                      </option>
                    ))}
                </select>
                <div className='error'></div>
              </div>
            </div>
            <div className='col-lg-3 col-sm-12'>
              <div className='mb-3'>
                <label htmlFor='billingCenter' className='form-label label-font'>
                  Billing Class
                </label>
                <select
                  title='Billing Center'
                  className='form-select'
                  id='billingCenter'
                  name='billingCenter'
                  value={model.billingCenter}
                  onChange={(e) => {
                    const x = { ...model };
                    x.billingCenter = e.target.value;
                    setModel(x);
                  }}
                >
                  <option selected>Select...</option>
                  {/* <option value='Not Selected'>Not Selected</option> */}
                  <option value='Consumer Understanding'>Consumer Understanding</option>
                  <option value='Downers Grove'>Downers Grove</option>
                  <option value='Managed Services'>Managed Services</option>
                  <option value='Michigan'>Michigan</option>
                  <option value='New York'>New York</option>
                  <option value='North Carolina Consulting'>North Carolina Consulting</option>
                  <option value='North Carolina Managed Services'>
                    North Carolina Managed Services
                  </option>
                  <option value='Product Understanding'>Product Understanding</option>
                  <option value='Texas'>Texas</option>
                  <option value='Training & Education'>Training & Education</option>
                </select>
                <div className='error'></div>
              </div>
            </div>
            {/* <div className='col-lg-3 col-sm-12'>
              <div className='mb-3'>
                <label htmlFor='projectType' className='form-label label-font'>
                  Project Type
                </label>
                <select
                  className='form-select'
                  id='projectType'
                  name='projectType'
                  value={model.projectType}
                  onChange={(e) => {
                    const x = { ...model };
                    x.projectType = e.target.value;
                    setModel(x);
                  }}
                >
                  <option selected>Select...</option>
                  <option value='Analysis/Reporting'>Analysis/Reporting</option>
                  <option value='Consulting'>Consulting</option>
                  <option value='Consumer Qual'>Consumer Qual</option>
                  <option value='Consumer Quant'>Consumer Quant.</option>
                  <option value='Descriptive Analysis'>Descriptive Analysis</option>
                  <option value='Discrimination Testing'>Discrimination Testing</option>
                  <option value='Facility Rental'>Facility Rental</option>
                  <option value='Panel/Lab Management'>Panel/Lab Management</option>
                  <option value='Panel Training'>Panel Training</option>
                  <option value='SCAN'>SCAN</option>
                  <option value='Snapshot'>Snapshot</option>
                  <option value='SpecSpeak'>SpecSpeak</option>
                  <option value='Training'>Training</option>
                  <option value='Other'>Other</option>
                  </select>
                  <div className='error'></div>
                  </div>
                </div> */}
            <div className='col-lg-3 col-sm-12'>
              <div className='mb-3'>
                <label htmlFor='panelType' className='form-label label-font'>
                  Panel Type
                </label>
                <select
                  className='form-select'
                  id='panelType'
                  name='panelType'
                  value={model.panelType}
                  onChange={(e) => {
                    const x = { ...model };
                    x.panelType = e.target.value;
                    setModel(x);
                  }}
                >
                  <option selected>Select...</option>
                  <option value='Aroma-NJ'>Aroma-NJ</option>
                  <option value='AG'>AG</option>
                  <option value='Critical Panel'>Critical Panel</option>
                  <option value='Discrimination-NC,'>Discrimination-NC,</option>
                  <option value='Food-DG'>Food-DG</option>
                  <option value='Food-NJ'>Food-NJ</option>
                  <option value='PCP'>PCP</option>
                  <option value='PQM'>PQM</option>
                  <option value='SCAN'>SCAN</option>
                </select>
                <div className='error'></div>
              </div>
            </div>
            <div className='col-lg-6 col-sm-12'>
              <div className='mb-3'>
                <label htmlFor='projectType' className='form-label label-font'>
                  Project Type
                </label>
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  isMulti={true}
                  options={[
                    { value: 'Analysis/Reporting', label: 'Analysis/Reporting' },
                    { value: 'Consulting', label: 'Consulting' },
                    { value: 'Consumer Qual', label: 'Consumer Qual' },
                    { value: 'Consumer Quant', label: 'Consumer Quant' },
                    { value: 'Descriptive Analysis', label: 'Descriptive Analysis' },
                    { value: 'Discrimination Testing', label: 'Discrimination Testing' },
                    { value: 'Facility Rental', label: 'Facility Rental' },
                    { value: 'Panel/Lab Management', label: 'Panel/Lab Management' },
                    { value: 'Panel Training', label: 'Panel Training' },
                    { value: 'SCAN', label: 'SCAN' },
                    { value: 'Snapshot', label: 'Snapshot' },
                    { value: 'SpecSpeak', label: 'SpecSpeak' },
                    { value: 'Training', label: 'Training' },
                    { value: 'Other', label: 'Other' }
                  ]}
                />
              </div>
              {/* <div className='mb-3'>
                <label htmlFor='projectType' className='form-label label-font'>
                  Project Type
                </label>
                <select
                  className='form-select'
                  id='projectType'
                  name='projectType'
                  value={model.projectType}
                  onChange={(e) => {
                    const x = { ...model };
                    x.projectType = e.target.value;
                    setModel(x);
                  }}
                >
                  <option selected>Select...</option>
                  <option value='Analysis/Reporting'>Analysis/Reporting</option>
                  <option value='Consulting'>Consulting</option>
                  <option value='Consumer Qual'>Consumer Qual</option>
                  <option value='Consumer Quant'>Consumer Quant.</option>
                  <option value='Descriptive Analysis'>Descriptive Analysis</option>
                  <option value='Discrimination Testing'>Discrimination Testing</option>
                  <option value='Facility Rental'>Facility Rental</option>
                  <option value='Panel/Lab Management'>Panel/Lab Management</option>
                  <option value='Panel Training'>Panel Training</option>
                  <option value='SCAN'>SCAN</option>
                  <option value='Snapshot'>Snapshot</option>
                  <option value='SpecSpeak'>SpecSpeak</option>
                  <option value='Training'>Training</option>
                  <option value='Other'>Other</option>
                </select>
                <div className='error'></div>
              </div> */}
            </div>
            <div className='col-lg-3 col-sm-12'>
              <div className='mb-3'>
                <label htmlFor='proposalDate' className='form-label label-font'>
                  Proposal Date
                </label>
                <input
                  type='date'
                  className='form-control'
                  id='proposalDate'
                  name='proposalDate'
                  value={model.proposalDate}
                  // onBlur={() => {
                  //   projectFormValidation.validate('proposalDate');
                  // }}
                  onChange={(e) => {
                    const x = { ...model };
                    x.proposalDate = e.target.value;
                    setModel(x);
                  }}
                />
                <div className='error'></div>
              </div>
            </div>
            <div className='col-lg-3 col-sm-12'>
              <div className='mb-3'>
                <label htmlFor='proposalAmount' className='form-label label-font'>
                  Proposal Amount
                </label>
                <CurrencyInput
                  id='proposalAmount'
                  // placeholder={`$${model.proposalAmount}`}
                  placeholder={
                    model.proposalAmount === null
                      ? '$0.00'
                      : model.proposalAmount.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD'
                        })
                  }
                  allowDecimals={true}
                  // className={`form-control ${className}`}
                  className='form-control'
                  // onBlur={() => {
                  //   projectFormValidation.validate('proposalAmount');
                  // }}
                  onValueChange={validateValue}
                  prefix={'$'}
                  step={10}
                />
                <div className='error'></div>
              </div>
            </div>
            <div className='col-lg-3 col-sm-12'>
              <div className='mb-3'>
                <label htmlFor='basic-multi-select' className='form-label label-font'>
                  Project Team
                </label>
                <Multiselect
                  className='basic-multi-select'
                  // disable
                  closeOnSelect={true}
                  loading={disableMultiSelect}
                  // loadingMessage='Loading...'
                  disable={disableMultiSelect}
                  avoidHighlightFirstOption={true}
                  onRemove={(selectedItems) => {
                    deleteTeamMember(selectedItems);
                    setDisableMultiSelect(true);
                  }}
                  onSelect={(event) => {
                    getSelectedEmployeeIds(event, true);
                  }}
                  selectedValues={selectedEmployee}
                  style={{ inputField: { maxWidth: '165px' } }}
                  options={employeeOption}
                  // showCheckbox
                  displayValue='displayName'
                />
              </div>
            </div>
            {/* <div className='col-lg-8 col-sm-12 align-self-center'> */}
            <div className='col-lg-3 col-sm-12 align-self-center'>
              <div className='mb-0'>
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value=''
                    checked={isChecked}
                    id='flexCheckDefault'
                    onChange={(e) => {
                      const x = { ...model };
                      x.exploratory = e.target.checked;
                      setIsChecked(e.target.checked);
                    }}
                  />
                  <label className='form-check-label text-start' htmlFor='flexCheckDefault'>
                    Exploratory{' '}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          //className='secondary-color-sp bg-light btn-click-cancel px-4'
          className='mx-1 px-4'
          variant='outline-secondary'
          onClick={() => resetModel()}
        >
          Cancel
        </Button>
        <Button
          //className='primary-bg-color-sp primary-button-color-sp-outline btn-click px-4'
          className='primary-bg-color-sp primary-button-color-sp-outline px-4'
          variant='primary'
          onClick={handleSubmit}
        >
          Save
        </Button>
        <div className='error'></div>
      </Modal.Footer>

      <ToastContainer className='p-3' position='middle-center'>
        <Toast bg='danger' onClose={() => setShow(false)} show={show} delay={3000} autohide>
          <Toast.Header closeButton={true}>
            <strong className='me-auto'>{response.status}</strong>
          </Toast.Header>
          <Toast.Body>{response.message}</Toast.Body>
        </Toast>
      </ToastContainer>
    </Modal>
  );
}

export default DialogModel;

<div className='error'></div>;
